<template>
  <div>
    <div class="content">
      <div class="top-title">
        <p class="title-text1">让微信用户找到你</p>
        <p class="title-text2">
          让企业实现应用“触手可及”的梦想，利用微信流量大口，助力企业快速步入互联网时代。
        </p>
      </div>
      <div class="box1 flex-between">
        <div
          class="box1-inner2 flex-y-center"
          v-for="(item, index) in box1List"
          :key="index"
        >
          <img
            :class="[index == 2 ? 'box1-img3' : 'box1-img2']"
            :src="item.icon"
            alt=""
          />
          <p>{{ item.text }}</p>
          <div class="flex-y-centers">
            <div class="box-inner">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="con2">
        <div class="con2-title">
          <p class="title-text1">选择微信小程序的理由</p>
          <p class="title-text2">
            小程序利用腾讯平台、流量资源等优势，将您的企业信息和产品以丰富多彩的样式展现给有需求的客户，并促使其与您产生更多互动及达成交易
          </p>
        </div>
        <div class="con2-inner">
          <img src="../../../assets/images/product-img5.png" alt="" />
          <div class="con-box con-box1">
            <p>全系列多场景用户超级流量</p>
            <p>
              每天数十亿次搜索请求，超过1亿用户
              浏览百度信息流、800亿次定位服务请求，覆盖用户生活主流场景
            </p>
          </div>
          <div class="con-box con-box2">
            <p>即用即走，不会给用户造成困扰</p>
            <p>
              小程序无需安装卸载，甚至无需注册（直接授权微信账号登录），也不会像一些APP那样频繁的推送消息给用户，因此不会造成用户困扰，诱发用户的反感情绪。
            </p>
          </div>
          <div class="con-box con-box3">
            <p>“附近的小程序”让消费者主动找到你</p>
            <p>
              “附近的小程序”可以看做是企业进行免费推广的利器。用户在【发现】搜索附近的小程序，能够看到5km以内的商家。
            </p>
          </div>
          <div class="con-box con-box4">
            <p>维护成本低</p>
            <p>
              正所谓“背靠大树好乘凉”，小程序基于微信这一成熟稳定的生态圈，同时有最大内存2M的限制，不仅开发相对简单，后期维护难度也会降低。
            </p>
          </div>
          <div class="con-box con-box5">
            <p>提升用户转化率</p>
            <p>
              企业开发小程序的核心是以盈利为目的，小程序的直接高效能够大幅度提高商品的点击率和转化率，不少电商类的导购小程序，由于结构足够轻量化，省去了APP中繁琐的内容，专攻特卖、拼团、秒杀，有效提升了商品的转化率。
            </p>
          </div>
          <div class="con-box con-box6">
            <p>有助于提升企业的品牌形象</p>
            <p>
              制作精良的小程序可以成为企业向外推广的一张有力名片，对于提升企业形象非常有利。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content3 flexCenter">
      <div class="con3 flexCenter">
        <img src="../../../assets/images/product-img6.jpg" alt="" />
        <div class="con3-inner">
          <p>获得更多优质客户</p>
          <p>
            小程序的应用场景很普遍，提升用户粘性，一键分享微信好友或群聊，方便线上传播；小程序码方便线下传播。线上线下结合，遍地开花，轻松获取更多优质客户。
          </p>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="con4">
        <div class="con4-title">
          <p class="title-text1">小程序开发之旅</p>
          <p class="title-text2">帮您快速开启小程序之旅</p>
        </div>
        <div class="con4-inner con4-inner1">
          <p>01</p>
          <p>注册微信小程序账号</p>
          <p>
            进入微信公众平台官网，选择“小程序”
            按要求填写相关信息即可完成微信小程序注册。
          </p>
          <img src="../../../assets/images/product-img8.png" alt="" />
        </div>
        <div class="con4-inner con4-inner2">
          <p>02</p>
          <p>开发者工具下载</p>
          <p>
            因微信小程序的代码及规范相对封闭，下载全新开发者工具，集成开发调试、代码编辑及程序发布等功能。
          </p>
          <img src="../../../assets/images/product-img9.png" alt="" />
        </div>
        <div class="con4-inner con4-inner3">
          <p>03</p>
          <p>代码上传</p>
          <p>在开发者工具中，选择将代码上传到微信小程序服务器。</p>
          <img src="../../../assets/images/product-img10.png" alt="" />
        </div>
        <div class="con4-inner con4-inner4">
          <p>04</p>
          <p>小程序发布</p>
          <p>
            登录微信公众平台并访问小程序首页按要求填写小程序相关信息及版本信息即可提交发布审核。
          </p>
          <img src="../../../assets/images/product-img11.png" alt="" />
        </div>
      </div>
    </div>
    <Button :minWidth="minWidth"></Button>
  </div>
</template>

<script>
import Button from "../../../../components/bottom.vue";
export default {
  name: "applet",
  data() {
    return {
      minWidth: 1320,
      seen: false,
      box1List: [
        {
          icon: require("../../../assets/images/product-img1.png"),
          id: 1,
          text: "观看",
          name: "让亿万微信用户上有需求的用户看到您的产品或服务信息",
        },

        {
          icon: require("../../../assets/images/product-img2.png"),
          id: 2,
          text: "点击",
        },
        {
          icon: require("../../../assets/images/product-img3.png"),
          id: 3,
          text: "访问",
        },
        {
          icon: require("../../../assets/images/product-img4.png"),
          id: 4,
          text: "使用",
        },
      ],
    };
  },
  components: { Button },
  methods: {

  },
};
</script>

<style scoped>
div {
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
/*  */

.flex-y-centers > div {
	display: none;
  width: 70%;
  height: 83px;
  margin: auto;
  box-shadow: 0 0 35px #fafafa;
  padding: 14px 20px;
  border-radius: 10px;

  font-size: 13px;
  color: #222;
}
.flex-y-centers {
  width: 100%;
  height: 100px;
  
}
.content {
  width: 1320px;
  margin: 0 auto;
}

.top-title {
  margin-top: 130px;
  padding: 0 30px;
  text-align: center;
}

.title-text1 {
  font-size: 31px;
  font-weight: bold;
  color: #222;
}

.title-text2 {
  color: #666;
  font-size: 15px;
  font-weight: bold;
  margin: 60px 0 85px;
}

.box1 {
  width: 100%;
  padding: 0 150px;
}

.box1-inner {
  position: relative;
}

.box1-inner2 {
  flex-direction: column;
  color: #222;
  font-size: 15px;
  width: 30%;
  position: relative;
}
.box-inner {
	position: absolute;
	top: 200px;
	left: 50%;
	transform: translateX(-50%);
}
.box1-inner2:hover .box-inner {
	display: block;
	animation: move 2s;
	transition: transform 1s;
}

@keyframes move {
	0% {
		transform: rotate(360deg);
	}
}
.box1-inner2 > p {
  margin: 45px 0 130px;
  font-weight: bold;
}

.box1-img1 {
  width: 64px;
  height: 45px;
  margin-top: 8px;
}

.box1-img2 {
  width: 63px;
  height: 63px;
}

.box1-img3 {
  width: 60px;
  height: 63px;
}

/*  */
.content2 {
  width: 100%;
  min-width: 1320px;
  height: 1147px;
  background: #f5f8fc;
}

.con2 {
  width: 1320px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.con2-title {
  position: absolute;
  width: 100%;
  top: 95px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.con2-inner {
  position: absolute;
  top: 380px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 60px;
  height: 800px;
}

.con2-inner > img {
  position: absolute;
  left: 50%;
  width: 600px;
  height: 555px;
  transform: translateX(-50%);
  z-index: 2;
}

.con-box {
  width: 320px;
  background: #fff;
  box-shadow: 0 0 30px #f1f4f8;
  position: absolute;
  z-index: 1;
  padding: 25px;
  border-radius: 10px;
  line-height: 20px;
}

.con-box1 {
  top: -40px;
  left: 75px;
}

.con-box2 {
  left: 75px;
  top: 200px;
}

.con-box3 {
  top: 444px;
  left: 75px;
}

.con-box4 {
  top: -40px;
  right: 75px;
}

.con-box5 {
  top: 200px;
  right: 75px;
}

.con-box6 {
  top: 444px;
  right: 75px;
}

.con-box > p:first-child {
  font-size: 16px;
  color: #222;
}

.con-box > p:last-child {
  margin-top: 16px;
  font-size: 14px;
  color: #666;
}

.content3 {
  height: 1000px;
  min-width: 1320px;
  width: 100%;
  background-image: url(../../../assets/images/product-img7.jpg);
  background-size: 100% 100%;
}

.con3 {
  width: 1320px;
  padding: 10px;
}

.con3 > img {
  height: 545px;
  width: 643px;
}

.con3-inner {
  width: 675px;
  height: 545px;
  background-color: #1f222a;
  padding: 0 60px;
  opacity: 0.5;
}

.con3-inner > p:nth-child(1) {
  color: #fff;
  font-size: 36px;
  margin-top: 140px;
}

.con3-inner > p:nth-child(2) {
  font-size: 16px;
  line-height: 25px;
  color: #adadaf;
  margin-top: 75px;
}

/*  */
.content4 {
  width: 100%;
  min-width: 1320px;
  height: 1013px;
  background: #f5f8fc;
}

.con4 {
  width: 1320px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  padding: 0 100px;
}

.con4-title {
  position: absolute;
  top: 115px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.con4-inner {
  position: absolute;
  width: 485px;
  height: 180px;
  border: 1px solid #e6e9f0;
  background: #fff;
  padding: 15px 205px 15px 15px;
}

.con4-inner1 {
  top: 310px;
  left: 100px;
}
.con4-inner1:hover{
	animation: move 2s;
	transform: translateY(-60px);
	
}
.con4-inner2:hover{
	animation: move 2s;
	transform: translateY(-60px);
	
}
.con4-inner3:hover{
	animation: move 2s;
	transform: translateY(60px);
	
}
.con4-inner4:hover{
	animation: move 2s;
	transform: translateY(60px);
	
}
.con4-inner2 {
  top: 310px;
  right: 100px;
}

.con4-inner3 {
  bottom: 210px;
  left: 100px;
}

.con4-inner4 {
  bottom: 210px;
  right: 100px;
}

.con4-inner > p:nth-child(1) {
  color: #75b9f6;
  font-size: 31px;
}

.con4-inner > p:nth-child(2) {
  font-size: 19px;
  color: #222;
  margin: 23px 0 16px;
}

.con4-inner > p:nth-child(3) {
  color: #666;
  font-size: 12px;
  z-index: 2;
}

.con4-inner > img {
  position: absolute;
  right: 0;
}

.con4-inner1 > img {
  width: 216px;
  height: 181px;
  top: -20px;
}

.con4-inner2 > img {
  width: 201px;
  height: 180px;
  top: -30px;
}

.con4-inner3 > img {
  width: 191px;
  height: 180px;
  top: -30px;
}

.con4-inner4 > img {
  width: 207px;
  height: 180px;
  top: -30px;
}
</style>
